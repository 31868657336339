import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { useState } from "react";
import emailjs from '@emailjs/browser';



export default function ContactForm(props) {

    emailjs.init('VUnmyzyOs81XTGMZz');

    const [formName, setFormName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formMessage, setFormMessage] = useState('');
    const [messageSending, setMessageSending] = useState(false);
    const [buttonMessage, setButtonMessage] = useState('Send');
    const [formItemsDisabled, setFormItemsDisabled] = useState(false);

    

    const submitMessage = (event) => {
        event.preventDefault();

        var templateParams = {
            to_name: 'Aden Webb',
            from_name: formName,
            from_email: formEmail,
            message: formMessage
        };

        setFormItemsDisabled(true);
        setMessageSending(true);

        emailjs.send('service_bv0wvo3', 'template_jdnmsw9', templateParams)
            .then(function (response) {
                setMessageSending(false);
                setButtonMessage('Message Sent!');
            }, function (error) {
                setMessageSending(false);
                setFormItemsDisabled(false);
                setButtonMessage('Send');
                props.open(true);
            });

        console.log(formName);
        console.log(formEmail);
        console.log(formMessage);
    }
    return (
        <>
            <form onSubmit={(e) => submitMessage(e)}>
                <TextField required variant='outlined' label='Name' type='name' name='name' value={formName} fullWidth sx={{ marginBottom: '20px' }} onInput={(e) => { setFormName(e.target.value); }} disabled={formItemsDisabled} />
                <TextField required variant='outlined' label='Email' type='email' name='email' value={formEmail} fullWidth sx={{ marginBottom: '20px' }} onInput={(e) => { setFormEmail(e.target.value); }} disabled={formItemsDisabled} />
                <TextField required variant='outlined' label='Message' type='message' name='message' value={formMessage} fullWidth multiline rows={4} sx={{ marginBottom: '20px' }} onInput={(e) => { setFormMessage(e.target.value); }} disabled={formItemsDisabled} />
                <LoadingButton size="large" loading={messageSending} variant='contained' type='submit' fullWidth disabled={formItemsDisabled}>{buttonMessage}</LoadingButton>
            </form>
            
        </>
    );
}