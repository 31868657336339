import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";





function Footer() {

  const date = new Date();
  const year = date.getFullYear();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction='row' spacing={3} justifyContent='center' mb={3}>
          <IconButton href="https://www.facebook.com/AdenWebbandTPW" target="_BLANK" size="large" sx={{ backgroundColor: 'white', color: 'black', '&:hover': { backgroundColor: '#d1d1d1', color: 'black' } }}>
            <Facebook />
          </IconButton>
          <IconButton size="large" href="https://www.youtube.com/@adenwebbandthepalmettowhiskeys" target="_BLANK" sx={{ backgroundColor: 'white', color: 'black', '&:hover': { backgroundColor: '#d1d1d1', color: 'black' } }}>
            <YouTube />
          </IconButton>
          <IconButton size="large" href="https://www.instagram.com/theadenwebb/" target="_BLANK" sx={{ backgroundColor: 'white', color: 'black', '&:hover': { backgroundColor: '#d1d1d1', color: 'black' } }}>
            <Instagram />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} paddingBottom={3} textAlign='center'>
        <Typography component='p' variant='body2'>
          © {year} Aden Webb. All rights reserved.
        </Typography>
      </Grid>
    </Grid >
  );
}

export default Footer;
