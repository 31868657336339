import './App.css';
import { Box, Chip, Container, createTheme, CssBaseline, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Fade, Zoom } from 'react-reveal';
import ContactForm from './ContactForm';

function createData(
  name: string,
  date: string, // expecting date format as MM/DD/YY
  location: string,
  time: string,
  cover: string,
) {
  const currentDate = new Date();

  const [month, day, year] = date.split('/');
  const eventDate = new Date(`20${year}`, parseInt(month) - 1, day); // Subtracting 1 from month since JS months are 0-indexed

  // Extract year, month, and day from both dates to ignore time when comparing
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const eventYear = eventDate.getFullYear();
  const eventMonth = eventDate.getMonth();
  const eventDay = eventDate.getDate();

  // Check if the event is in the past
  if (
    eventYear < currentYear ||
    (eventYear === currentYear && eventMonth < currentMonth) ||
    (eventYear === currentYear && eventMonth === currentMonth && eventDay < currentDay)
  ) {
    return null;
  }

  return { name, date, location, time, cover };
}

const rows = [
  createData(`Cowboy Up^`, '8/9/24', 'Greenville, SC', '9:00pm', 'Free'),
  createData(`Ink and Ivy^`, '8/9/24', 'Greenville, SC', '8:00pm', 'Free'),
  createData(`Raines on Broadway*`, '8/14/24', 'Anderson, SC', '6:00pm', 'Free'),
  createData(`Wendell's Dippin Branch`, '8/16/24', 'Anderson, SC', '9:30pm', 'Price At Door'),
  createData(`Wendell's Dippin Branch`, '8/17/24', 'Anderson, SC', '9:30pm', 'Price At Door'),
  createData(`Raines on Broadway*`, '8/21/24', 'Anderson, SC', '6:00pm', 'Free'),
  createData(`Cowboy Up*`, '8/22/24', 'Greenville, SC', '6:00pm', 'Free'),
  createData(`Port Grill^`, '8/14/24', 'Greenwood, SC', '8:00pm', 'Free'),
  createData(`Ziggys`, '8/14/24', 'Saint Simons Island, GA', '8:00pm', 'Free'),
  createData(`Cowboy Up*`, '9/12/24', 'Greenville, SC', '6:00pm', 'Free'),
  createData(`Wendell's Dippin Branch`, '9/20/24', 'Anderson, SC', '9:30pm', 'Price At Door'),
  createData(`Anderson Jeep Fest and Expo`, '9/21/24', 'Anderson, SC', '11:30am', 'Free'),
  createData(`Wendell's Dippin Branch`, '9/21/24', 'Anderson, SC', '9:30pm', 'Price At Door'),
  createData(`Rains on Main*`, '9/27/24', 'Anderson, SC', '7:00pm', 'Free'),
  createData(`Wendell's Dippin Branch`, '10/5/24', 'Anderson, SC', '9:30pm', 'Price At Door'),
];

const spaceBetweenSections = 50;


function Website(props) {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const Logo = styled.img`
    max-width: 500px;
    height: auto;
    width: 100%;
    padding: 0 50px;
  `

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container>

        <Grid item
          xs={12}
          paddingY={{ xs: 0, md: 15 }}
          paddingX={3}
          paddingLeft={{ xs: 16, sm: 40, md: 3 }}
          marginBottom={12}
          sx={{ background: 'url("/images/cover.jpg") rgb(0,0,0,0.5)', backgroundBlendMode: 'multiply', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionY: 'top', }}
        >
          <center>
            <Zoom>
              <Logo src='/images/logo-white.png' alt='Aden Webb and The Palmetto Whiskeys Logo' />
            </Zoom>
          </center>
        </Grid>
        <Container>
          <Fade delay={0} up>
            <Grid container paddingBottom={spaceBetweenSections}>
              <Grid item xs={12} textAlign='center' marginBottom={4}>
                <Typography component='h4' variant='h4' fontSize={{ md: '60px' }} fontWeight={700}>
                  Upcoming Shows
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper} elevation={4}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell sx={{ fontWeight: '700', fontSize: '1em' }}>Venue</StyledTableCell>
                        <StyledTableCell align="left" sx={{ fontWeight: '700', fontSize: '1em' }}>Date</StyledTableCell>
                        <StyledTableCell align="left" sx={{ fontWeight: '700', fontSize: '1em' }}>Location</StyledTableCell>
                        <StyledTableCell align="left" sx={{ fontWeight: '700', fontSize: '1em' }}>Time</StyledTableCell>
                        <StyledTableCell align="left" sx={{ fontWeight: '700', fontSize: '1em' }}>Entry Fee</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <>
                          {row &&
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.date}</StyledTableCell>
                              <StyledTableCell align="left">{row.location}</StyledTableCell>
                              <StyledTableCell align="left">{row.time}</StyledTableCell>
                              <StyledTableCell align="left">{row.cover}</StyledTableCell>
                            </TableRow>
                          }
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} textAlign='center' marginTop={3}>
                <Chip label='*Aden Webb solo shows' sx={{ mx: 1 }} />
                <Chip label='^Aden Webb duo shows' sx={{ mx: 1 }} />
              </Grid>
            </Grid>
          </Fade>
          <Fade left>
            <Grid container justifyContent='center' alignContent='stretch' paddingX={1.5}>
              <Grid item xs={12} md='auto' textAlign={{ xs: 'center', md: 'right' }} paddingRight={{ xs: 0, md: 4 }} display={{ md: 'flex' }}>
                <Box marginY='auto'>
                  <Typography component='h4' variant='h4' fontSize={{ md: '60px' }} fontWeight={700}>
                    Meet The Band
                  </Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md={5} lg={5.6} textAlign={{ xs: 'center', md: 'left' }} paddingLeft={{ xs: 0, md: 4 }} lineHeight={{ xs: 1.8, md: 2 }} paddingTop={{ xs: 2, md: 0 }}>
                <Typography component='p' variant='body1' fontSize={{ xs: 15, md: 18 }}>
                  Aden Webb and The Palmetto Whiskeys is a country rock band from South Carolina. The band is led by lead singer, Aden Webb, who is joined by Dylan Rogers and Michael Waters on electric guitars, Austin Woodard on bass guitar, and Matt Holbrook on drums. They play a mix of classic and new country songs with a rock twist, blending traditional country sounds with modern rock influences to create a unique and dynamic sound. With a passion for music and a dedication to entertaining their audiences, Aden Webb and The Palmetto Whiskeys are quickly making a name for themselves in the music scene.
                </Typography>
              </Grid>
            </Grid>
          </Fade>
          <Fade right>
            <Grid container justifyContent='center' alignContent='stretch' paddingX={1.5} paddingTop={spaceBetweenSections}>
              <Grid item xs={12} md='auto' textAlign={{ xs: 'center', md: 'right' }} paddingLeft={{ xs: 0, md: 4 }} display={{ md: 'flex' }} order={{ xs: 1, md: 3 }}>
                <Box marginY='auto'>
                  <Typography component='h4' variant='h4' fontSize={{ md: '60px' }} fontWeight={700}>
                    Hear The Music
                  </Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem={true} style={{ order: '2' }} />
              <Grid item xs={12} md={5} lg={5.6} textAlign={{ xs: 'center', md: 'left' }} paddingRight={{ xs: 0, md: 4 }} lineHeight={{ xs: 1.8, md: 2 }} paddingTop={{ xs: 2, md: 0 }} order={{ xs: 3, md: 1 }}>
                <Box flexItem>
                  <iframe width="100%" height="auto" style={{ aspectRatio: '16/9' }} src="https://www.youtube.com/embed/4XIhae5sZ8k" title="East Coast Town" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                </Box>
              </Grid>
            </Grid>
          </Fade>
          <Fade left>
            <Grid container justifyContent='center' alignContent='stretch' paddingX={1.5} paddingY={spaceBetweenSections}>
              <Grid item xs={12} md='auto' textAlign={{ xs: 'center', md: 'right' }} paddingRight={{ xs: 0, md: 4 }} display={{ md: 'flex' }}>
                <Box marginY='auto'>
                  <Typography component='h4' variant='h4' fontSize={{ md: '60px' }} fontWeight={700}>
                    Booking
                  </Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md={5} lg={5.6} textAlign={{ xs: 'center', md: 'left' }} paddingLeft={{ xs: 0, md: 4 }} lineHeight={{ xs: 1.8, md: 2 }} paddingTop={{ xs: 2, md: 0 }}>
                <ContactForm open={props.setOpen} />
              </Grid>
            </Grid>
          </Fade>
          <Fade right>
            <Grid container justifyContent='center' alignContent='center' paddingX={1.5} paddingBottom={spaceBetweenSections}>
              <Grid item xs={12} md='auto' textAlign={{ xs: 'center', md: 'right' }} paddingLeft={{ xs: 0, md: 4 }} display={{ md: 'flex' }} order={{ xs: 1, md: 3 }}>
                <Box marginY='auto'>
                  <Typography component='h4' variant='h4' fontSize={{ md: '60px' }} fontWeight={700}>
                    Our Partners
                  </Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem={true} style={{ order: '2' }} />
              <Grid item xs={12} md={5} lg={5.6} textAlign={{ xs: 'center', md: 'left' }} paddingRight={{ xs: 0, md: 4 }} lineHeight={{ xs: 1.8, md: 2 }} paddingTop={{ xs: 2, md: 0 }} order={{ xs: 3, md: 1 }}>
                <Stack direction='column' spacing={8} sx={{ pt: { xs: 5, md: 0 } }}>
                  <img src='/images/er_logo_wg.svg' alt='Emerald Records' style={{ width: '100%', height: 'auto', maxHeight: '100px', objectFit: 'contain', objectPosition: 'center' }} />
                  <a href='https://www.knlpromotions.com' target='_BLANK'><img src='/images/KNL.png' alt='KNL Promotions' style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'contain', objectPosition: 'center' }} /></a>
                </Stack>
              </Grid>
            </Grid>
          </Fade>
        </Container>
      </Grid>
    </ThemeProvider>
  );
}

export default Website;
