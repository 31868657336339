import './App.css';
import Website from './Website';
import FailedToSend from './FailedToSend';
import { useState } from 'react';
import Footer from './Footer';




function App() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Website setOpen={setOpen}/>
      <Footer />
      <FailedToSend open={open} setOpen={setOpen} />
    </>
  );
}

export default App;
